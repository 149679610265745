import React, { useEffect } from 'react';
import { motion } from "framer-motion";
import mixpanel from 'mixpanel-browser';

mixpanel.init('928068c30b134aac2b92eaac45c5bb87', {
    track_pageview: true,
    debug: true
});

const itemLarge = {
    hidden: {  
        y: 40,
        opacity: 0.4,
        scale: 0.9
    },
    visible: { 
        y: 0,
        opacity: 1,
        scale: 1,
        transition: {
          type: "ease",
          duration: 0.2
        } 
    }
}
const itemSmall = {
    hidden: {  
        y: 40,
        opacity: 0.4,
        scale: 0.8
    },
    visible: { 
        y: 0,
        opacity: 1,
        scale: 1,
        transition: {
          type: "ease",
          duration: 0.4
        } 
    }
}
const arrowButton = {
    hover: { 
        rotate: 45,
        transition: {
            type: "ease",
            duration: 0.1
        } 
    }
}

function Content() {
    useEffect(() => {
        mixpanel.track_links(".item-small", "Dribbble_link_clicked");
        mixpanel.track_links(".item-large--1", "Plugin_link_clicked");
    }, []);
    return (
        <div className="content">
            <div className="container">

                <motion.div 
                    initial="hidden"
                    whileInView="visible"
                    whileHover="hover"
                    variants={itemLarge}
                    className="item-large item-case-study item-large--2"
                    // href="#"
                    // target="_blank"
                >
                    <div className="item-large--preview item-large-preview--2" style={{backgroundImage: "url(" + "./assets/static/works/yard--preview.webp" + ")", backgroundSize: 'cover'}}>

                        {/* <video playsInline autoPlay muted loop className="item-large--preview-video">
                            <source src="https://darwin-public.s3.eu-west-1.amazonaws.com/v7_go/V7+Go+Hero+-+Video+Desktop+(Lo-res).mp4" type="video/mp4" />
                        </video> */}
                    </div>
                    <div className="item-subtitle">
                        <div className="item-subtitle--heading">
                            <p>Case study</p>
                            <h6>Yard</h6>
                        </div>
                        <div className="item-subtitle--read">
                            <span>Coming soon</span>
                            {/* <span>Read</span>
                            <motion.img 
                                variants={arrowButton}
                                src="assets/static/icons/arrow_forward--dark-grey.svg" alt="" 
                                className="item-subtitle--icon"
                            /> */}
                        </div>
                    </div>
                </motion.div>



                <motion.a
                    initial="hidden"
                    whileInView="visible"
                    whileHover="hover"
                    variants={itemSmall}
                    className="item-small item-small--1"
                    href="https://dribbble.com/shots/19216965-MacOS-Air-Quality-Widget-Concept"
                    target="_blank"
                >
                    <div className="item-small--preview item-small-preview--1" style={{backgroundImage: "url(" + "./assets/static/works/work-1.webp" + ")",}}></div>
                    <div className="item-subtitle">
                        <div className="item-subtitle--heading">
                            <p>UI concept</p>
                            <h6>MacOS Air Quality Widget</h6>
                        </div>
                        <motion.img 
                            variants={arrowButton}
                            src="assets/static/icons/arrow_forward--dark-grey.svg" alt="" 
                            className="item-subtitle--icon"
                        />
                    </div>
                </motion.a>
                <motion.a
                    initial="hidden"
                    whileInView="visible"
                    whileHover="hover"
                    variants={itemSmall}
                    className="item-small item-small--2"
                    href="https://dribbble.com/shots/23713710-Blockchain-explorer-Validators-consensus-state"
                    target="_blank"
                >
                    <div className="item-small--preview item-small-preview--2" style={{backgroundImage: "url(" + "./assets/static/works/work-2.webp" + ")",}}></div>
                    <div className="item-subtitle">
                        <div className="item-subtitle--heading">
                            <p>UI concept</p>
                            <h6>Blockchain explorer</h6>
                        </div>
                        <motion.img 
                            variants={arrowButton}
                            src="assets/static/icons/arrow_forward--dark-grey.svg" alt="" 
                            className="item-subtitle--icon"
                        />
                    </div>
                </motion.a>
                
                <motion.a 
                    initial="hidden"
                    whileInView="visible"
                    whileHover="hover"
                    variants={itemLarge}
                    className="item-large item-large--1"
                    href="https://www.figma.com/community/plugin/1411292832384115287/graph-calculator"
                    target="_blank"
                >
                    <div className="item-large--preview item-large-preview--1">
                        <video playsInline autoPlay muted loop className="item-large--preview-video">
                            <source src="./assets/static/videos/graph-visualiser.mp4" type="video/mp4" />
                        </video>
                    </div>
                    <div className="item-subtitle">
                        <div className="item-subtitle--heading">
                            <p>Figma plugin</p>
                            <h6>Graph Visualiser</h6>
                        </div>
                        <div className="item-subtitle--read">
                            <span>Figma Community</span>
                            <motion.img 
                                variants={arrowButton}
                                src="assets/static/icons/arrow_forward--dark-grey.svg" alt="" 
                                className="item-subtitle--icon"
                            />
                        </div>
                    </div>
                </motion.a>


                <motion.a
                    initial="hidden"
                    whileInView="visible"
                    whileHover="hover"
                    variants={itemSmall}
                    className="item-small item-small--3"
                    href="https://dribbble.com/shots/23759101-Financial-Market-Whale-Analytics-Tool"
                    target="_blank"
                >
                    <div className="item-small--preview item-small-preview--3" style={{backgroundImage: "url(" + "./assets/static/works/work-3.webp" + ")",}}></div>
                    <div className="item-subtitle">
                        <div className="item-subtitle--heading">
                            <p>UI concept</p>
                            <h6>"Whale" Analytics Tool</h6>
                        </div>
                        <motion.img 
                            variants={arrowButton}
                            src="assets/static/icons/arrow_forward--dark-grey.svg" alt="" 
                            className="item-subtitle--icon"
                        />
                    </div>
                </motion.a>
                <motion.a 
                    initial="hidden"
                    whileInView="visible"
                    whileHover="hover"
                    variants={itemSmall}
                    className="item-small item-small--4"
                    href="https://dribbble.com/shots/23678712-MacOS-Sound-Mixer-Widget-Concept"
                    target="_blank"
                >
                    <div className="item-small--preview item-small-preview--4" style={{backgroundImage: "url(" + "./assets/static/works/work-4.webp" + ")",}}></div>
                    <div className="item-subtitle">
                        <div className="item-subtitle--heading">
                            <p>UI concept</p>
                            <h6>MacOS Sound Mixer Widget</h6>
                        </div>
                        <motion.img 
                            variants={arrowButton}
                            src="assets/static/icons/arrow_forward--dark-grey.svg" alt="" 
                            className="item-subtitle--icon"
                        />
                    </div>
                </motion.a>

                <motion.div
                    initial="hidden"
                    whileInView="visible"
                    whileHover="hover"
                    variants={itemLarge}
                    className="item-large item-case-study item-large--3"
                    // href="#"
                    // target="_blank"
                >
                    <div className="item-large--preview item-large-preview--3" style={{backgroundImage: "url(" + "./assets/static/works/smplr--preview.webp" + ")", backgroundSize: 'cover'}}>
                        {/* <video playsInline autoPlay muted loop className="item-large--preview-video">
                            <source src="https://darwin-public.s3.eu-west-1.amazonaws.com/v7_go/V7+Go+Hero+-+Video+Desktop+(Lo-res).mp4" type="video/mp4" />
                        </video> */}
                    </div>
                    <div className="item-subtitle">
                        <div className="item-subtitle--heading">
                            <p>Case study</p>
                            <h6>Smplr</h6>
                        </div>
                        <div className="item-subtitle--read">
                            <span>Coming soon</span>
                            {/* <span>Read</span>
                            <motion.img 
                                variants={arrowButton}
                                src="assets/static/icons/arrow_forward--dark-grey.svg" alt="" 
                                className="item-subtitle--icon"
                            /> */}
                        </div>
                    </div>
                </motion.div>

                <motion.a 
                    initial="hidden"
                    whileInView="visible"
                    whileHover="hover"
                    variants={itemSmall}
                    className="item-small item-small--5"
                    href="https://dribbble.com/shots/23855055-Web3-Blockchain-Explorer-Asset-stats-widget"
                    target="_blank"
                >
                    <div className="item-small--preview item-small-preview--5" style={{backgroundImage: "url(" + "./assets/static/works/work-5.webp" + ")",}}></div>
                    <div className="item-subtitle">
                        <div className="item-subtitle--heading">
                            <p>UI concept</p>
                            <h6>Blockchain Explorer: Asset stats widget</h6>
                        </div>
                        <motion.img 
                            variants={arrowButton}
                            src="assets/static/icons/arrow_forward--dark-grey.svg" alt="" 
                            className="item-subtitle--icon"
                        />
                    </div>
                </motion.a>
                <motion.a 
                    initial="hidden"
                    whileInView="visible"
                    whileHover="hover"
                    variants={itemSmall}
                    className="item-small item-small--6"
                    href="https://dribbble.com/shots/19306046-Crypto-mining-external-rigs-dashboard"
                    target="_blank"
                >
                    <div className="item-small--preview item-small-preview--6" style={{backgroundImage: "url(" + "./assets/static/works/work-6.webp" + ")",}}></div>
                    <div className="item-subtitle">
                        <div className="item-subtitle--heading">
                            <p>UI concept</p>
                            <h6>Crypto mining external rigs dashboard</h6>
                        </div>
                        <motion.img 
                            variants={arrowButton}
                            src="assets/static/icons/arrow_forward--dark-grey.svg" alt="" 
                            className="item-subtitle--icon"
                        />
                    </div>
                </motion.a>
            </div>
        </div>
    );
}

export default Content;